import { useEffect, useState } from 'react';
import { arrow, github, linkedin, medium, twitter } from './images';
import './input.css';
import Marquee from 'react-fast-marquee';
import { motion } from 'framer-motion';

const links = [
  {
    name: 'Github',
    url: 'https://github.com/pguduguntla',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/un.phase/',
  },
  {
    name: 'Dribbble',
    url: 'https://dribbble.com/praneethguduguntla',
  },
];

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1100;

  if (isMobile) {
    return (
      <>
        <div className='absolute top-4 '>
          <motion.div
            initial={{ opacity: 0, scale: 0.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 400,
              damping: 30,
              mass: 1,
              duration: 0.8,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <Marquee speed={20} loop={0} className='text-xs text-[#A3A3A3]'>
              BASED IN ILLINOIS. FROM CALIFORNIA. COPYRIGHT PRANEETH GUDUGUNTLA.
            </Marquee>
          </motion.div>
        </div>

        <div className='absolute bottom-4'>
          <motion.div
            initial={{ opacity: 0, scale: 0.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 400,
              damping: 30,
              mass: 1,
              duration: 0.8,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <Marquee speed={20} loop={0} className='text-xs text-[#A3A3A3]'>
              BASED IN ILLINOIS. FROM CALIFORNIA. COPYRIGHT PRANEETH GUDUGUNTLA.
            </Marquee>
          </motion.div>
        </div>

        <div className='h-screen w-screen flex flex-col items-center  text-[#444444]'>
          <div className='w-full absolute left-1/2 transform -translate-x-1/2 top-1/3  margin-auto'>
            <div className='w-full absolute z-40 -top-32 p-8'>
              <motion.div
                initial={{ opacity: 0, scale: 0.9, y: 100 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 400,
                  damping: 30,
                  mass: 1,
                  duration: 0.2,
                  delay: 0.1,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <LabelAndContent
                  label='Praneeth Guduguntla'
                  content="I'm a developer and designer. I'm currently a student at UIUC studying Computer Science. I'm passionate about building products that are useful and beautiful."
                />
              </motion.div>
            </div>
            <div>
              <motion.div
                initial={{ opacity: 0, scale: 0.2 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 400,
                  damping: 30,
                  mass: 1,
                  duration: 0.8,
                  delay: 0.1,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <BackgroundImage />
              </motion.div>
              <div className='w-full mx-auto'>
                <motion.div
                  initial={{ opacity: 0, scale: 0.2 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    type: 'spring',
                    stiffness: 400,
                    damping: 30,
                    mass: 1,
                    duration: 0.3,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                >
                  <Links isMobile={isMobile} />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='absolute top-4 '>
        <motion.div
          initial={{ opacity: 0, scale: 0.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 30,
            mass: 1,
            duration: 0.8,
            delay: 0.1,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Marquee speed={20} loop={0} className='text-xs text-[#A3A3A3]'>
            BASED IN ILLINOIS. FROM CALIFORNIA. COPYRIGHT PRANEETH GUDUGUNTLA.
          </Marquee>
        </motion.div>
      </div>

      <div className='absolute bottom-4'>
        <motion.div
          initial={{ opacity: 0, scale: 0.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 30,
            mass: 1,
            duration: 0.8,
            delay: 0.1,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Marquee speed={20} loop={0} className='text-xs text-[#A3A3A3]'>
            BASED IN ILLINOIS. FROM CALIFORNIA. COPYRIGHT PRANEETH GUDUGUNTLA.
          </Marquee>
        </motion.div>
      </div>

      <div className='h-screen w-screen flex flex-col items-center justify-center text-[#444444]'>
        <div className='w-full absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 margin-auto'>
          <div className='w-[1000px] absolute z-40 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto p-8'>
            <motion.div
              initial={{ opacity: 0, scale: 0.9, y: 100 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              transition={{
                type: 'spring',
                stiffness: 400,
                damping: 30,
                mass: 1,
                duration: 0.2,
                delay: 0.1,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <LabelAndContent
                label='Praneeth Guduguntla'
                content="I'm a developer and designer. I'm currently a student at UIUC studying Computer Science. I'm passionate about building products that are useful and beautiful."
              />
            </motion.div>
          </div>
          <div>
            <motion.div
              initial={{ opacity: 0, scale: 0.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                type: 'spring',
                stiffness: 400,
                damping: 30,
                mass: 1,
                duration: 0.8,
                delay: 0.1,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <BackgroundImage />
            </motion.div>
            <div className='w-[1000px] mx-auto'>
              <motion.div
                initial={{ opacity: 0, scale: 0.2 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 400,
                  damping: 30,
                  mass: 1,
                  duration: 0.3,
                  delay: 0.1,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <Links />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Links(props) {
  return (
    <div className='mx-auto flex p-8'>
      {!props.isMobile && (
        <div className='flex gap-4'>
          <a
            target='_blank'
            href='https://github.com/pguduguntla'
            className='hover:underline uppercase text-sm'
            rel='noreferrer'
          >
            I'm a developer
          </a>
          <a
            target='_blank'
            href='https://praneethg.notion.site/Praneeth-s-Design-Portfolio-918af080d2874d578130865030ef68f2'
            className='hover:underline  uppercase text-sm'
            rel='noreferrer'
          >
            I'm a designer
          </a>
        </div>
      )}
      <div className='flex-grow' />
      <div className='flex gap-4'>
        {links.map((link) => {
          return (
            <a
              target='_blank'
              href={link.url}
              className='hover:underline uppercase text-sm'
              rel='noreferrer'
            >
              {link.name}
            </a>
          );
        })}
      </div>
    </div>
  );
}

function BackgroundImage(props) {
  return (
    <svg
      className='w-full opacity-10 mb-4'
      viewBox='0 0 1512 302'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 301C252 301 504 301 756 301C1008 301 1260 301 1512 301'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        opacity='0.86'
        d='M0 251C252 284.333 504 301 756 301C1008 301 1260 284.333 1512 251'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        opacity='0.73'
        d='M0 201C252 267.667 504 301 756 301C1008 301 1260 267.667 1512 201'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        opacity='0.59'
        d='M0 151C252 251 504 301 756 301C1008 301 1260 251 1512 151'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        opacity='0.46'
        d='M0 101C252 234.333 504 301 756 301C1008 301 1260 234.333 1512 101'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        opacity='0.32'
        d='M0 51C252 217.667 504 301 756 301C1008 301 1260 217.667 1512 51'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        opacity='0.19'
        d='M0 1C252 201 504 301 756 301C1008 301 1260 201 1512 1'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
      />
    </svg>
  );
}

function LabelAndContent(props) {
  return (
    <aside className='flex flex-col md:flex-row md:items-start md:justify-between w-full'>
      <h1 className='text-lg font-bold md:mr-8 min-w-min uppercase'>
        {props.label}
      </h1>
      <p className='mt-2 md:mt-0 w-[400px]'>{props.content}</p>
    </aside>
  );
}

export default App;
